import {get,post,put} from "./utils/request";
import md5 from 'js-md5'
export default {
    data() {
      return {
        ozhas: "Ozhas",
      };
    },
    methods: {
      $get: (url, params) => get(url, params),
      $post: (url, params) => post(url, params),
      $put: (url, params) => put(url, params),

      $ms(str,onOk){
        this.$message.success(str,1,onOk);
      },
      $mw(str,onOk){
        this.$message.warning(str,1,onOk);
      },
      $me(str,onOk){
        this.$message.error(str,1,onOk);
      },
      $mc(content,onOk,onCancel) {
        this.$confirm({
          title: '系统提示',
          content,
          onOk,
          onCancel,
        });
      },
      $encrypt(str){
        return md5(md5(md5(str+'ozhas').split('').reverse().join(''))+'ace')
      }
    },
   

    // filters: {
    //   fmtGender(val) {
    //     return ["男", "女", "未知"][val];
    //   },
    // },
  }