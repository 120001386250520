import axios from "axios";
import nprogress from "nprogress";
import qs from "qs";

// 可以使用自定义配置新建一个实例
const instance = axios.create({
  baseURL: process.env.VUE_APP_BASEURL,
  // timeout: 1000,
  headers: { "X-Custom-Header": "foobar","openid":"oOweE5df3dapRR-khFxxPSKzG4ko" },
});

// 添加请求拦截器
instance.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    nprogress.start();
    if (process.env.NODE_ENV === "development") {
      let { method, url, data, params } = config;
      let args = method === "get" ? params : data;
      console.log(
        `${method}请求了=>${url}`,
        args ? `参数是:${qs.stringify(args)}` : ""
      );
    }
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    nprogress.done();
    if (process.env.NODE_ENV === "development") {
      console.log(`响应了:` , response.data);
    }
    return response;
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);

// export default instance;
export async function get(url, params) {
  let { data } = await instance.get(url, { params });
  // let data  = await instance.get(url, { params });
  return data;
}

export async function post(url, params) {
  let { data } = await instance.post(url, params);
  // let data = await instance.post(url,  params );
  return data;
}


export async function put(url, params) {
  let { data } = await instance.put(url, params);
  return data;
}